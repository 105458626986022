
import axios from 'axios'
import {localStorage,sessionStorage,cookieStorage} from './sessionStore'
import VueRouter from '../../router'

import jwtDecode from 'jwt-decode'
import router from "@/router";



let toast = ({html}) => {
    console.log(html)
}

let onUserChanged = (user) => {};

class Auth {

    constructor(Vue, options){


        let defaultOption = {

            expirationTime: 3600,

            // Route Redirection
            authRedirect:       {path: '/login'},
            forbiddenRedirect:  {path: '/403'},
            notFoundRedirect:   {path: '/404'},


            // Api
            registerData:       {url: '/api/register',      method: 'POST', redirect: null},
            loginData:          {url: '/api/login',         method: 'POST', redirect: null, rememberMe: false},
            logoutData:         {url: '/api/logout',        method: 'POST', redirect: null, makeRequest: false},
            fetchData:          {url: '/api/info',          method: 'GET'},
            refreshTokenData:   {url: '/api/token/refresh', method: 'POST'},
            downloadData:       {method: 'GET', responseType: 'blob'},

            onStartLoading: () => {},
            onFinishLoading: () => {},
        }

        for (let a in options) {
            if(defaultOption[a]) {
                options[a] = Object.assign({}, defaultOption[a], options[a])
            }
        }

        this._options = Object.assign({}, defaultOption, options)

        if(this._options.onUserChanged)
            onUserChanged = this._options.onUserChanged

        this.store = sessionStorage.get('accessToken') ? sessionStorage : localStorage
        this.http = axios
        this.router = VueRouter


        this.onStartLoading = this._options.onStartLoading
        this.onFinishLoading = this._options.onFinishLoading


        this.watch = new Vue({
            data() {
                return {
                    data: {

                    },
                    user: {},
                    dateExpiration: null,
                    redirect: null,
                    authenticated: null
                }
            },
            methods: {
                isExpired() {
                    return this.dateExpiration < new Date()
                }
            },
            watch: {
                user: (val) => {
                    onUserChanged(val)
                }
            }
        });


        this.router.beforeEach((to, from, next) => {

            if (to.meta.auth === false && this.check()) {
                from = to
                to = {path: '/'}
                next(to)
            } else if (to.meta.auth === true && !this.check()) {
                from = to
                to = this._options.authRedirect
                next(to)
            } else if (to.meta.allowed_roles){
                let roles = to.meta.allowed_roles
                roles.push('super_admin')
                roles.push('admin')

                let isGranted = false
                for(let r in roles) {
                    if (this.isGranted(roles[r])) {
                        isGranted = true
                        break;
                    }
                }
                if(isGranted) {
                    next()
                } else {
                    next('/403')
                }

            } else {
                next()
            }

            this.watch.redirect = {to, from}
            //console.log(this.watch.redirect.from.path, this.watch.redirect.to.path)

        })

        // first data loading
        if(this.token().accessToken) {
            this._extractUserFromToken(this.token().accessToken)
        }
    }

    check() {
        return this.watch.authenticated;
    }



    redirect() {
        return this.watch.redirect
    }

    clearSession(){
        // console.log('clearSession()', this.store.get('accessToken'), this.store.get('refreshToken'))

        this.store.remove('accessToken')
        this.store.remove('refreshToken')
        this.store.remove('rememberMe')


        this.watch.user = {}
        this.watch.authenticated = false

    }

    login(data) {

        return new Promise((resolve, reject) => {
            let _data = Object.assign({}, this._options.loginData, data)
            _data._noToken = true
            _data.headers = {
                'Content-Type': 'application/json'
            }

            this.fetch(_data)
                .then(response => {
                    this.token(response.data.token, response.data.refresh_token, _data.rememberMe)
                    if(_data.redirect) {
                        this.router.push(_data.redirect)
                    }
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }


    register(data) {
        return new Promise((resolve, reject) => {
            let _data = Object.assign({}, this._options.registerData, data)
            _data._noToken = true
            this.fetch(_data)
                .then(response => {
                    if(_data.redirect) {
                        this.router.push(_data.redirect)
                    }
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })

    }

    logout(data) {
        return new Promise((resolve, reject) => {

            let _data = Object.assign({}, this._options.logoutData, data)

            let _success = () => {
                this.clearSession()
                if(_data.redirect && _data.redirect !== this.router.currentRoute.path ) {
                    this.router.push(_data.redirect)
                }
            }

            if(_data.makeRequest) {
                this.fetch(_data)
                    .then(response => {
                        _success()
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            } else {
                _success()
                resolve()
            }
        })
    }


    download(data) {

        return new Promise((resolve, reject) => {
            let _data = Object.assign({}, this._options.downloadData, data)

            let filename = null
            if(_data.filename) {
                filename = _data.filename
            }


            this.fetch(_data)
                .then(response => {
                let blob = new Blob([response.data]);

                if(!filename) {
                    let contentDisposition = response.headers["content-disposition"];
                    if (contentDisposition) {
                        let match = contentDisposition.match(/filename\*=(.+?)'(.*?)'(.+?)(?:;|$)/i);
                        console.log(match)
                        if (match) {
                            // Extraire l'encodage et le nom de fichier encodé
                            const encoding = match[1].toUpperCase(); // Exemple : 'UTF-8'
                            const urlEncodedFilename = match[3];

                            // Pour le moment, on gère uniquement UTF-8 de manière standard
                            if (encoding === 'UTF-8') {
                                filename = decodeURIComponent(urlEncodedFilename);
                            } else {
                                // Gérer d'autres encodages si nécessaire (nécessite des bibliothèques supplémentaires en JS)
                                filename = urlEncodedFilename;
                            }
                        } else {
                            // Sinon, chercher la forme classique de filename
                            match = contentDisposition.match(/filename=(.+?)(?:;|$)/i);
                            if (match) {
                                filename = match[1];
                            }
                        }
                    } else {
                        let fileParts = _data.url.split('/');
                        filename = fileParts[fileParts.length-1];

                        fileParts = _data.url.split('.');
                        if(fileParts.length < 2) {
                            filename += ".txt";
                        }
                    }
                }

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    let blobURL = window.URL.createObjectURL(blob);
                    let tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.setAttribute('download', filename);

                    if (typeof tempLink.download === 'undefined') {
                        tempLink.setAttribute('target', '_blank');
                    }

                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                }
                resolve(response)
            }).catch(async err => {
                let blob = err.response.data;
                if(blob) {
                    const text = await blob.text()
                    try {
                        err.response.data = JSON.parse(text)
                    } catch (e) {
                        err.response.data = text
                    }
                }
                reject(err)
            })
        })
    }

    fetch(data) {

        return new Promise((resolve, reject) => {
            let _data = Object.assign({}, this._options.fetchData, data)

            if(_data.noLoading !== true) {
                this.onStartLoading()
            }


            let _config = {
                url: _data.url,
                method: _data.method?.toLowerCase() ?? 'get',
                data: _data.data ? _data.data : {},
                signal: _data.signal ? _data.signal : null,
            }

            let contentType = 'application/json;charset=UTF-8'
            if(_data.data !== 'get' && _data.data instanceof FormData) {
                contentType = 'multipart/form-data'
            }


            _config.headers = {
                'Content-type': contentType,
            }

            if(cookieStorage.get('local')) {
                _config.headers['Accept-Language'] = cookieStorage.get('local')
            }

            if(_data.headers) {
                _config.headers = {
                    ..._config.headers,
                    ..._data.headers
                }
            }


            if(_data.responseType) {
                _config.responseType = _data.responseType
            }

            if(!_data._noToken && this.token()) {
                _config.headers = {
                    ..._config.headers,
                    Authorization: 'Bearer '+ this.token().accessToken
                }
            }

            let _error = err => {
                if(_data.noLoading !== true) {
                    this.onFinishLoading()
                }
                if (err && err.response && err.response.status === 401) {
                    this.clearSession()
                    if(!_data._noToken) {
                        this.router.push(this._options.authRedirect)
                    }
                }
                reject(err)
            }

            if(_config.data && _config.method.toLowerCase() === 'get') {
                _config.params = _config.data
                delete _config.data
            }


            if(!_config.data) {
                _config.data = {}
            }

            this.http(_config).then(
                response => {
                    if(_data.noLoading !== true) {
                        this.onFinishLoading()
                    }
                    resolve(response)
                }
            ).catch(
                err => {
                    if (!this.http.isCancel(err)) {
                        let res = err.response;
                        if (res) {

                            if (res.status === 403) {
                                _error(err)
                                // if(this._options.forbiddenRedirect) {
                                //     this.router.push(this._options.forbiddenRedirect)
                                // }
                            } else if (res.status === 404) {
                                _error(err)
                                // if(this._options.notFoundRedirect) {
                                //     this.router.push(this._options.notFoundRedirect)
                                // }
                            } else if (res.status === 401 && !_data._noToken) {
                                this.refreshToken().then(response => {
                                    res.config.headers['Authorization'] = 'Bearer ' + this.token().accessToken
                                    this.http(res.config).then(response => {
                                        if (_data.noLoading !== true) {
                                            this.onFinishLoading()
                                        }
                                        resolve(response)
                                    }).catch(error => {
                                        _error(error)
                                    })
                                }).catch(err => {
                                    _error(err)
                                })
                            } else {
                                _error(err)
                            }

                        } else {
                            _error(err)
                        }
                    }
                }
            )
        })
    }

    refreshToken() {
        return new Promise((resolve, reject) => {

            const refreshToken = this.token().refreshToken

            if(refreshToken) {

                this.http({
                    url: this._options.refreshTokenData.url,
                    method: this._options.refreshTokenData.method,
                    data: {refresh_token: refreshToken},
                }).then(response => {
                    this.token(response.data.token, response.data.refresh_token, true)
                    resolve(response)
                }).catch( error => {
                    reject(error)
                })
            } else {
                reject({response: {status: 401, message: 'noToken'}})
            }

        })
    }

    token(accessToken, refreshToken, rememberMe) {

        // Get Token
        if(!accessToken) {

            let _accessToken
            let _refreshToken


            _accessToken = this.store.get('accessToken')
            _refreshToken = this.store.get('refreshToken')


            if(!_accessToken && !_refreshToken) {
                this.watch.authenticated = false
                return false
            }

            this.watch.authenticated = true

            return {
                accessToken: _accessToken ,
                refreshToken: _refreshToken
            }

        } else {

            // console.log('set token')
            // Set Token
            let expiration = ( rememberMe ? new Date().getTime() + 63072000000 : new Date().getTime() + this._options.expirationTime * 1000 )

            if(accessToken) {
                //console.log('set accessToken',expiration,  accessToken)
                this.store.set('accessToken', accessToken, expiration)
            }

            if(refreshToken && rememberMe) {


                this.store.set('refreshToken', refreshToken, expiration)

            }

            this._extractUserFromToken(accessToken)

            this.watch.authenticated = true
            // console.log('set authenticated', true)
        }

    }

    _extractUserFromToken(accessToken) {
        if(!this.watch.user.username) {

            let _jwtData = jwtDecode(accessToken)
            this.watch.dateExpiration = new Date(_jwtData.exp  * 1000)
            let r = []
            for(let a in _jwtData.roles) {
                r.push(_jwtData.roles[a].toLowerCase().replace('role_', ''))
            }

            const firstname = _jwtData.firstname ? _jwtData.firstname.trim() : ''
            const lastname = _jwtData.lastname ? _jwtData.lastname.trim() : ''

            let fullName = firstname
            if(fullName && lastname) {
                fullName += ' '
            }
            fullName += lastname
            const initial =  fullName.trim().split(' ').map(w => w.substr(0, 1)).join('')

            this.user({
                initial: initial,
                username: _jwtData.username,
                email: _jwtData.email,
                firstname: _jwtData.firstname,
                lastname: _jwtData.lastname,
                roles: r,
            })
        }
    }

    isGranted(role) {
        return this.user().roles ? this.user().roles.includes(role) : false
    }

    user(data) {
        if (data) {
            this.watch.user = data;
        }

        return this.watch.user || {};
    }

    isExpired() {
        return this.watch.isExpired() || false;
    }

    serialize(data) {
        let formData = new FormData()
        for(let a in data) {
            if(data[a])
                formData.append(a, data[a])
        }
        return formData
    }


}

export default Auth
