<template>
  <v-autocomplete
      class="select-organization"
      ref="input"
      v-model="data"
      :label="label"
      :loading="loading"
      :items="items"
      v-on="$listeners"
      :rules="rules"
      item-text="name"
      item-value="id"
      :search-input.sync="searchText"
      :solo="solo"
      :flat="flat"
      :rounded="rounded"
      :outlined="outlined"
      :clearable="clearable"
      :append-icon="appendIcon"
      :readonly="readonly"
      :disabled="disabled"
      :multiple="multiple"
      :placeholder="placeholder"
      @keydown="loadItems(searchText)"
      no-filter
      hide-details
      hide-no-data
      autocomplete="nope"
  >
    <template #item="{item, on, attrs}">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-avatar>
          <v-icon left>{{ commonIcons().organization.type[item.type] }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.path }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template #selection="{item}"  v-if="!selectionWithNoExtra">
      <div class="item">
        <div class="title">
          {{ item.name }}
        </div>
        <div class="subtitle-1">
          {{ item.path }}
        </div>
      </div>
    </template>

  </v-autocomplete>
</template>

<script>

import OrganizationRepository from "@repository/OrganizationRepository";

export default {
  data(){
    return {
      loading: false,
      items: [],
      data: this.value,
      searchText: "",
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: String,
    type: String,
    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    solo: Boolean,
    flat: Boolean,
    outlined: Boolean,
    rounded: Boolean,
    rules: Array,
    multiple: Boolean,
    appendIcon: String,
    placeholder: String,
    label: String,
    selectionWithNoExtra: Boolean,
    withoutFilter: Boolean,
  },

  watch: {

    data(val) {
      this.$emit('input', val)
      this.loadItems(val)
    },

    value(val) {
      this.data = val
    }
  },

  mounted() {
    if(this.data) {
      this.loadItems(this.data)
    }
  },

  methods: {
    loadItems(searchText) {
      if(!searchText?.trim()) {
        this.items = []
        return
      }
      this.loading = true
      OrganizationRepository.search({
        searchText: searchText,
        withoutFilter: this.withoutFilter,
        limit: 25,
      }).then(result => {
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(err.data.message ?? err.message)
      })
    },

    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss">
.select-organization {
  &.v-input--is-focused {
    .v-select__selections {
      input {
        min-width: 30px !important;
      }
    }
  }
  .item {
    margin-right: 10px;
    .title {
      font-size: 18px !important;
      line-height: 18px;
      font-weight: bold;
      margin: 3px 0;
      color: #E53C22;
    }
    .subtitle-1 {
      margin: 3px 0;
      font-size: 16px !important;
      line-height: 16px;
    }
  }
}
</style>
