<template>
  <v-row class="form-field" :class="{active: focus, 'has-error': error}">
    <v-col :sm="colLeft"  class="label" @click="setFocus">
      <transition name="fade-scale">
        <span class="error-message red darken-1" v-if="error">
          {{ errorMessages }}
        </span>
      </transition>
      <span>
        {{ label }}
      </span>

    </v-col>
    <v-col :sm="colRight" class="input-wrapper">
      <v-text-field
          v-if="type === 'string'"
          ref="input"
          v-on="$listeners"
          v-model="data"
          :rules="rules"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :placeholder="placeholderCleaned"
          :prepend-icon="prependIcon"
          :prepend-inner-icon="prependInnerIcon"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          :append-outer-icon="appendOuterIcon"
          :type="inputType"
          :class="{'active' : focus}"
          :hide-details="hideDetails"
          :clearable="clearable"
          :readonly="readonly"
          :disabled="disabled"
      >
        <template #append>
          <slot name="append"></slot>
        </template>
      </v-text-field>
      <wysiwyg-field
          v-if="type === 'wysiwyg'"
          ref="input"
          v-on="$listeners"
          v-model="data"
          @blur="onBlur"
          :placeholder="placeholderCleaned"
          :disabled="disabled"
      />
      <v-textarea
          v-if="type === 'textarea'"
          ref="input"
          :rows="rows"
          auto-grow
          v-on="$listeners"
          v-model="data"
          :rules="rules"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :placeholder="placeholderCleaned"
          :prepend-icon="prependIcon"
          :prepend-inner-icon="prependInnerIcon"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          :append-outer-icon="appendOuterIcon"
          :type="inputType"
          :class="{'active' : focus}"
          :hide-details="hideDetails"
          :clearable="clearable"
          :readonly="readonly"
          :disabled="disabled"
      >
        <template #append>
          <slot name="append"></slot>
        </template>
      </v-textarea>
      <available-select
          v-if="type === 'available-select'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :rules="rules"
          :type="inputType"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :clearable="clearable"
          :readonly="readonly"
          :disabled="disabled"
          :filter="filter"
          :placeholder="placeholderCleaned"
      />
      <electricity-index-select
          v-if="type === 'electricity-index-select'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :rules="rules"
          :type="inputType"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :clearable="clearable"
          :readonly="readonly"
          :disabled="disabled"
          :placeholder="placeholderCleaned"
      />
      <tariff-select
          v-if="type === 'tariff-select'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :rules="rules"
          :type="inputType"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :clearable="clearable"
          :readonly="readonly"
          :disabled="disabled"
          :placeholder="placeholderCleaned"
          :filter-query="filterQuery"
          :multiple="multiple"
      />
      <price-index-select
          v-if="type === 'price-index-select'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :rules="rules"
          :type="inputType"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :placeholder="placeholderCleaned"
          :clearable="clearable"
      />
      <charging-point-select
          v-if="type === 'charging-point-select'"
          ref="input"
          v-model="data"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          :clearable="clearable"
          :disabled="disabled"
          :placeholder="placeholderCleaned"
          :readonly="readonly"
          :rules="rules"
          :solo="focus"
          :type="inputType"
          @blur="onBlur"
          @focus="onFocus"
          @update:error="onError($event)"
      />
      <customer-select
          v-if="type === 'customer-select'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :rules="rules"
          :type="inputType"
          :multiple="multiple"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :clearable="clearable"
          :placeholder="placeholderCleaned"
          :selection-with-no-extra="selectionWithNoExtra"
      />
      <organization-select
          v-if="type === 'organization-select'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :rules="rules"
          :type="inputType"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :clearable="clearable"
          :placeholder="placeholderCleaned"
          :selection-with-no-extra="selectionWithNoExtra"
      />
      <user-select
          v-if="type === 'user-select'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          :multiple="multiple"
          :chips="chips"
          :deletable-chips="deletableChips"
          @update:error="onError($event)"
          :rules="rules"
          :type="inputType"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :placeholder="placeholderCleaned"
          :selection-with-no-extra="selectionWithNoExtra"
      />
      <v-autocomplete
          v-if="type === 'select'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :rules="rules"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :placeholder="placeholderCleaned"
          :filter="filter"
          :clearable="clearable"
          :readonly="readonly"
          :items="items"
          :disabled="disabled"
          :return-object="returnObject"
          :item-text="itemText"
          :item-value="itemValue"
          :multiple="multiple"
          :hide-details="hideDetails"
          :chips="chips"
          :deletable-chips="deletableChips"
      >
        <template #selection="{item}" v-if="$scopedSlots.selection">
          <slot name="selection" :item="item"></slot>
        </template>
        <template #item="{item}" v-if="$scopedSlots.item">
          <slot name="item" :item="item"></slot>
        </template>
        <template #append v-if="$scopedSlots.append">
          <slot name="append"></slot>
        </template>
      </v-autocomplete>
      <v-select
          v-if="type === 'select-no-ac'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          @update:error="onError($event)"
          :rules="rules"
          :append-icon="error ? 'mdi-alert-circle-outline' : null"
          v-model="data"
          :placeholder="placeholderCleaned"
          :filter="filter"
          :clearable="clearable"
          :readonly="readonly"
          :items="items"
          :disabled="disabled"
          :return-object="returnObject"
          :item-text="itemText"
          :item-value="itemValue"
          :multiple="multiple"
          :hide-details="hideDetails"
          :chips="chips"
          :deletable-chips="deletableChips"
      >
        <template #selection="{item}" v-if="$scopedSlots.selection && !chips">
          <slot name="selection" :item="item"></slot>
        </template>
        <template #item="{item}" v-if="$scopedSlots.item">
          <slot name="item" :item="item"></slot>
        </template>
        <template #append v-if="$scopedSlots.append">
          <slot name="append"></slot>
        </template>
      </v-select>
      <v-switch
          v-if="type === 'switch'"
          ref="input"
          @focus="onFocus"
          @blur="onBlur"
          :rules="rules"
          v-model="data"
          hide-details
          :placeholder="placeholderCleaned"
          :readonly="readonly"
          :disabled="disabled"
      />
      <tree-select-organization
          v-if="type === 'tree-select-organization'"
          ref="input"
          @focus="onFocus"
          @blur="onBlur"
          @options-updated="e => $emit('options-updated', e)"
          :rules="rules"
          v-model="data"
          :exclude="exclude"
          class="treeselect"
          :clearable="clearable"
          :readonly="readonly"
          :disabled="disabled"
          :multiple="multiple"
          :placeholder="placeholderCleaned"
      />
      <vue-tel-input-vuetify
          v-if="type === 'phone'"
          label=""
          :value="data"
          ref="input"
          @focus="onFocus"
          @blur="onBlur"
          :preferred-countries="['fr', 'be', 'nl']"
          disabled-fetching-country
          :hide-details="hideDetails"
          :rules="rules"
          @input="onPhoneInput"
          :clearable="clearable"
          :class="{solo: focus}"
          :placeholder="placeholderCleaned ? placeholderCleaned : $t('enter-phone-number')"
          :readonly="readonly"
          :disabled="disabled"
      />
      <localized-string
          v-if="type === 'localized-string'"
          ref="input"
          :solo="focus"
          @focus="onFocus"
          @blur="onBlur"
          :rules="rules"
          v-model="data"
          hide-details
          :active-language.sync="activeLanguage"
          :placeholder="placeholderCleaned"
          :readonly="readonly"
          :disabled="disabled"
          :wysiwyg="wysiwyg"
          :disable-language-btns="disableLanguageBtns"
          :class="{'active' : focus}"
      />
      <date-picker
          v-if="type === 'date'"
          ref="input"
          @focus="onFocus"
          @blur="onBlur"
          :rules="rules"
          v-model="data"
          hide-details
          :placeholder="placeholderCleaned"
          :readonly="readonly"
          :disabled="disabled"
          :prependIcon="prependIcon"
          :clearable="clearable"
          :range="range"
      />
    </v-col>
  </v-row>
</template>

<script>
import TreeSelectOrganization from "@blocks/Select/TreeSelectOrganization.vue";
import ChargingPointSelect from "@blocks/Select/ChargingPointSelect.vue";
import AvailableSelect from "@blocks/Select/AvailableSelect.vue";
import WysiwygField from "@blocks/WysiwygField";
import LocalizedString from "@blocks/LocalizedString.vue";
import ElectricityIndexSelect from "@blocks/Select/ElectricityIndexSelect.vue";
import TariffSelect from "@blocks/Select/TariffSelect.vue";
import PriceIndexSelect from "@blocks/Select/PriceIndexSelect.vue";
import CustomerSelect from "@blocks/Select/CustomerSelect.vue";
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import UserSelect from "@blocks/Select/UserSelect.vue";


export default {

  components: {
    WysiwygField,
    TreeSelectOrganization,
    ChargingPointSelect,
    AvailableSelect,
    LocalizedString,
    ElectricityIndexSelect,
    UserSelect,
    TariffSelect,
    PriceIndexSelect,
    CustomerSelect,
    OrganizationSelect
  },

  data(){
    return {
      shouldValidate: true,
      data: this.value,
      focus: false,
      error: false,
      errorMessages: null,
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    exclude: String,
    label: String,
    value: { type: [String, Array, String, Number, Boolean, Object], default: ""},
    rules: Array,
    component: String,
    type: {type: String, default: 'string'},
    inputType: {type: String, default: 'text'},
    solo: Boolean,
    hideDetails: {type: Boolean, default: true},
    prependIcon: String,
    prependInnerIcon: String,
    appendOuterIcon: String,
    placeholder: [String, Number, Boolean],
    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    returnObject: Boolean,
    multiple: Boolean,
    wysiwyg: Boolean,
    rows: {type: [Number, String], default: 1},
    items: Array,
    filterQuery: { type: Object, default: () => ({}) },
    filter: {type: Function, default: undefined},
    itemText: {type: String, default: "text"},
    itemValue: {type: String, default: "value"},
    colField: {type: [Number, String], default: 6},
    range: {type: Boolean},
    activeLanguage: String,
    disableLanguageBtns: Boolean,
    chips: Boolean,
    deletableChips: Boolean,
    selectionWithNoExtra: Boolean,
  },

  inject: {
    form: { default: null }
  },

  created () {
    this.form && this.form.register(this)
  },

  beforeDestroy () {
    this.form && this.form.unregister(this)
  },

  mounted() {
    if(this.type === 'available-select') {
      this.$refs.input.$refs.input.$refs.input.addEventListener('focus', () => {
        this.focus = true
      })
    }
    if(this.type === 'select') {
      this.$refs.input.$refs.input.addEventListener('focus', () => {
        this.focus = true
      })
    }
    if(this.type === 'switch') {
      if(this.$refs.input && this.$refs.input.$refs.input) {
        this.$refs.input.$refs.input.addEventListener('focus', this.onFocus)
        this.$refs.input.$refs.input.addEventListener('blur', this.onBlur)
      }
    }
  },

  destroyed() {
    if(this.type === 'switch') {
      if(this.$refs.input && this.$refs.input.$refs.input) {
        this.$refs.input.$refs.input.removeEventListener('focus', this.onFocus)
        this.$refs.input.$refs.input.removeEventListener('blur', this.onBlur)
      }
    }
  },

  watch: {
    data(val) {
      if(this.type === 'switch') {
        if(!val) {
          val = false
        }
      } else {
        if(!val) {
          val = null
        }
      }
      if(val !== this.value) {
        this.$emit('input', val)
      }
    },

    value(val) {
      if(this.data !== val) {
        this.data = val
      }
    },

    activeLanguage(val) {
      this.$emit('update:active-language', val)
    },


  },

  computed: {

    hasError() {
      return this.error
    },

    colLeft() {

      if(this.colField) {
        return 12 - this.colField
      }

      return 6
    },

    colRight() {

      if(this.colField) {
        return this.colField
      }

      return 6
    },

    placeholderCleaned() {
      return this.placeholder ? "" + this.placeholder : null
    },
  },

  methods: {
    onFocus() {
      if(this.readonly) {
        return
      }
      this.focus = true
    },

    onBlur() {
      if(!this.solo) {
        this.focus = false
      }

      if(this.type === 'available-select') {
        this.$refs.input.$refs.input.isMenuActive = false
      }
    },

    setFocus() {
      this.$nextTick(() => {
        if(this.$refs.input.focus) {
          this.$refs.input.focus()
        } else if(this.$refs.input.$refs.input.focus) {
          this.$refs.input.$refs.input.focus()
        }
      })
    },

    refresh() {
      if(this.type === 'tree-select-organization') {
        this.$refs.input.refresh()
      }
    },

    onError(hasError) {
      this.error = hasError
      if(hasError) {
        let errorBucket = this.$refs.input?.errorBucket || this.$refs.input?.$refs.input?.errorBucket
        if(errorBucket.length > 0) {
          this.errorMessages = ''
          errorBucket.forEach(errorMessage => {
            if(this.errorMessages) {
              this.errorMessages += ', '
            }
            this.errorMessages += errorMessage
          })
        }
      }

    },

    onPhoneInput(formattedNumber, { number, valid }) {
      this.data = number.e164
      this.error = number.input && !valid;
      this.errorMessages = this.$t('phone-number-not-valid')
    },


    validate(force, value) {
      if(this.error || this.$refs.input?.hasError || this.$refs.input?.$refs.input?.hasError) {
        this.onError(true)
        return false
      }
      return true
    },

    reset () {
      this.data = null
    },

    resetValidation() {
      this.error = false
    }

  },


}
</script>

<style lang="scss">
.form-field {
  &.row {
    & > * {
      padding: 0 12px;
    }


    &.has-error {
      .label, input, .v-input__icon {
        color: red !important;
      }
    }
    .col {
      &.label {
        text-transform: uppercase;
        font-weight: 300;
        text-align: right;
        padding-right: 10px;
        width: 60%;
        color: #758093;
        transform: translateY(13px);
        .error-message {
          display: inline-block;
          color: white;
          font-weight: 600;
          font-size: 14px;
          text-transform: initial;
          padding: 2px 10px;
          margin-right: 10px;
          border-radius: 4px;
          box-shadow: 0 0 5px 0 #9097a7;
          right: 0;
        }

      }


      &.input-wrapper > * {
        position: relative;
        &:not(.treeselect) {
          transform: translateY(-8px);
        }
        input, .v-input__icon {
          color: black;
          font-weight: 600;
          padding-left: 5px;
        }
        &.v-text-field--solo, .v-text-field--solo {
          transform: translateY(0px);
          .v-input__control {
            .v-input__slot {
              box-shadow: 0 10px 30px #00000029 !important;
              input, textarea, .v-input__icon {
                color: var(--v-primary-base) !important;
                padding-left: 0;
              }
            }
          }
        }

        .v-input__control {
          .v-input__slot {
            &:before {
              border-color: #75809340 !important;
            }
          }
        }

        .v-btn-toggle {
          transform: translateY(15px);
        }

      }

    }

    .field-wysiwyg {
      margin-top: 20px;
    }
  }



  .vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control, &.active .vue-treeselect__control{
    background-color: white;
    border-radius: 4px;
    border: 1px solid #758093 !important;
    box-shadow: 0 10px 30px #00000029 !important;
    padding-left: 5px;
    padding-right: 15px;
    .vue-treeselect__single-value {
      color: var(--v-primary-base) !important;
    }
    .vue-treeselect__control-arrow-container {
      .vue-treeselect__control-arrow {
        color: var(--v-primary-base) !important;
      }
    }
    .vue-treeselect__placeholder {
      padding-left: 10px;
      color: var(--v-primary-base) !important;
    }
  }

  .vue-tel-input-vuetify {
    //transform: translateY(-8px);
    .v-select {
      min-width: unset;
    }

    & > .v-text-field {
      transform: translateY(-11px);
    }

    &.solo {
      & > .v-text-field {
        border-radius: 4px;
        margin-top: 0;
        transform: translateY(-9px);
        .v-input__control {
          min-height: 48px;
          .v-input__slot {
            box-shadow: 0 10px 30px #00000029 !important;
            border: 1px solid #758093;
            background: white;
            padding: 0 8px ;
            input, .v-input__icon {
              color: var(--v-primary-base) !important;
            }
            .v-input__append-inner {
              align-self: unset;
              margin: 0;
            }
            &:before, &:after {
              content: unset;
            }
          }
        }
      }
    }
  }

  &.active {
    &.row {
      .col {
        &.label {
          color: var(--v-primary-base);
        }
      }
    }
  }
}
</style>
