import {mapActions, mapGetters} from "vuex";
import {EventBus} from "@includes/EventBus";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import i18n from "@plugins/i18n";
dayjs.extend(utc)
dayjs.extend(timezone)
import Tools from "@includes/tools";

export default {
  data() {
    return {

    }
  },

  computed: {
    ...mapGetters([
      'currentLocale',
      'currentUser',
    ]),

    isDeveloper() {
      if(this.currentUser) {
        return [
            'issam.kallali@gmail.com',
            'user@onefield.io',
            'maxime.vandermeersch@onefield.io',
            'pierre-yves.davister@zeborne.com',
            'julien.davy@zeborne.com',
        ].includes(this.currentUser.email)
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchCurrentUser',
    ]),
    formatDate(value, format) {
      if (!format) {
        format = "DD/MM/YY [à] HH[h]mm"
      }
      if (value) {
        return dayjs(value).format(format)
      }
      return value
    },
    formatUtcDate(value, format) {
      if (!format) {
        format = "DD/MM/YYYY"
      }
      if (value) {
        return dayjs.utc(value).format(format)
      }
      return value
    },

    formatDuration(seconds) {
      if(seconds > 86400) {
        return dayjs.duration(seconds, 'seconds').format(this.$t('charging-point.evse_format_duration_day'))
      }
      return dayjs.duration(seconds, 'seconds').format(this.$t('charging-point.evse_format_duration'))
    },

    ucFirst(text) {
      return Tools.ucfirst(text)
    },

    setUnsavedChanges() {
      global.formDataNotSaved = true
    },

    cleanUnsavedChanges() {
      global.formDataNotSaved = false
    },

    switchLocale(locale) {
      this.$store.commit('currentLocale', locale)
    },

    showLoading(show) {
      EventBus.$emit('loading', show)
    },

    /**
     * Clones an object and returns the cloned object with optional selected keys.
     *
     * @param {Object} obj - The object to be cloned.
     * @param {Array} keys - Optional. An array of keys to be included in the cloned object.
     * @return {Object} - The cloned object with optional selected keys.
     */
    cloneObj(obj, keys = []) {

      // Clone the object.
      let clonedObj = obj ? JSON.parse(JSON.stringify(obj)) : null

      // If keys are provided, only include the selected keys in the cloned object.
      if(keys?.length > 0) {
        const newClonedObj = {};
        keys.forEach(key => {
          if (key in clonedObj) {
            newClonedObj[key] = clonedObj[key];
          }
        });
        return newClonedObj
      }
      return clonedObj
    },



    numberFormat(number, decimals = 2) {
      return new Intl.NumberFormat(this.currentLocale, {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(number)
    },

    enumsToList(enums, addValueToLabel = false) {
      let list = []
      for(let a in enums) {
        let text = enums[a]
        if(addValueToLabel) {
          text += ' ('+ a +')'
        }
        list.push({text, value: a})
      }
      return list
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.$dialog.notify.success(this.$t('copied'), {position: 'right bottom'})
    },

    ocppStatusToStatus(connectorStatus) {

      switch(connectorStatus) {
        case 'Available':
        case 'Preparing':
        case 'Finishing':
          return 'AVAILABLE'

        case 'SuspendedEVSE':
        case 'SuspendedEV':
          return 'BLOCKED'

        case 'Unavailable':
          return 'OFFLINE'

        case 'Charging':
          return 'CHARGING'

        case 'Faulted':
          return 'OUTOFORDER'

        default:
          return 'UNKNOWN'
      }
    },

    getOrganizationGroupTypeColor(type) {
      switch (type) {
        case 'free-charge':
          return 'green';
        case 'private-charge':
          return 'red';
        case 'refund-tariff':
          return 'orange';
        case 'organization-payment':
          return 'blue';
        default:
          return '';
      }
    },

    issueSubject(issue) {
      if(!issue) {
        return '';
      }
      if(issue.type === 'SESSION_OVER_36H') {
        return '> 36h'
      }
      if(issue.type === 'SESSION_ZERO_KWH') {
        return '0Kw'
      }
      if(issue.type === 'STATION_ERROR') {
        return `${issue.chargingPointError.code} (con: ${issue.chargingPointError.connectorId})${issue.errorRepeated > 0 ? ' (r: ' +issue.errorRepeated+ ')' : ''}`
      }
      if(issue.type === 'STATION_OFFLINE') {
        let str =  this.$t('reports.offline.offline') + ' '

        if(issue.chargingPointDisconnection.reconnectedAt) {
          str += this.$t('during').toLowerCase()
        } else {
          str += this.$t('since').toLowerCase()
        }

        str += ' ' + this.disconnectionDuration(issue.chargingPointDisconnection)

        return str
      }

      return issue.subject ? issue.subject : '-'
    },

    issueStatusColor(issue) {
      if(issue.status === 'RESOLVED') {
        return 'green'
      }
      if(issue.status === 'IN_PROGRESS') {
        return 'orange'
      }
      if(issue.status === 'CLOSED') {
        return 'black'
      }
      if(issue.status === 'PENDING') {
        return 'grey'
      }
      if(issue.status === 'NEW') {
        return 'blue'
      }
      if(issue.status === 'OPENED') {
        return 'purple'
      }
      return 'red'
    },

    issuePriorityColor(issue) {
      if(issue.priority === 'LOW') {
        return 'grey'
      }
      if(issue.priority === 'HIGH') {
        return 'orange'
      }
      if(issue.priority === 'CRITICAL') {
        return 'red'
      }

      return 'black'
    },

    disconnectionDuration(chargingPointDisconnection) {
      if(chargingPointDisconnection) {
        let date1 = dayjs(chargingPointDisconnection.disconnectedAt)
        let date2 = chargingPointDisconnection.reconnectedAt ? dayjs(chargingPointDisconnection.reconnectedAt) : dayjs()
        return this.formatDuration(date2.diff(date1, 'seconds'))
      }
    },

    checkPasswordComplexity(password) {
      return new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})").test(password)
    },

    localizedString(data) {
      if(data?.length > 0) {
        const localizedStrings = {}
        for(let i = 0; i < data.length; i++) {
          localizedStrings[data[i].language] = data[i].value
        }
        if(localizedStrings[this.currentLocale]) {
          return localizedStrings[this.currentLocale]
        }
        if(localizedStrings['en']) {
          return localizedStrings['en']
        }
        if(localizedStrings[data[0].language]) {
          return localizedStrings[data[0].language]
        }
      }
      return ""
    },

    commonIcons() {
      return {
        organization: {
          type: {
            group: "mdi-folder",
            company: "mdi-domain",
            individual: "mdi-account",
          }
        }
      }
    },

    isSpecialUser() {
      for (let id in this.currentUser.organizations) {
        return [
            'd0f5c1f4-966d-44c3-b1b3-50894898fc0c', // Mars
            '2c19bf21-48fe-4293-8a10-15aa71b183e2', // Mars
            '1e0bdf98-9231-49c2-b27d-577475abe175', // Schindler BE
            '10a2dbcf-e1e1-4cab-adf7-3eb48ba2242d', // Schindler BE
            '12fc0a7a-1440-4480-ba27-d28cd3426217', // Schindler BE
            'dbf04e0e-086e-4cc4-9c6d-63b72dad8d83', // Schindler BE
        ].includes(id)
      }
      return false
    },

    notifyError(error) {
      let errorMessage = error ? (error?.response?.data?.message || error) : 'error-occurred';
      let parameters = error?.response?.data?.parameters || null
      let notifyMessage = this.$te(errorMessage) ? this.$t(errorMessage, parameters) : errorMessage;
      this.$dialog.notify.error(notifyMessage);
    },


    notifySuccess(messageKey, parameters) {
      this.$dialog.notify.success(this.$t(messageKey, parameters));
    },

    addressToString(address) {
        let str = ''
        if(address) {
            if(address.number) {
              str += address.number
            }
            if(address.number && address.street) {
              str += ', '
            }
            if(address.street) {
              str +=  address.street
            }
            if(address.zipcode) {
              str += ', ' + address.zipcode
            }
            if(address.city) {
              str += ' ' + address.city
            }
            if(address.country) {
              str += ', ' + address.country
            }
        }
        return str
    },

    isNullOrUndefined(obj) {
      return obj === null || obj === undefined
    },

    nl2br(str) {
      return Tools.nl2br(str)
    }
  }
}
