import Vue from "vue";
import VueRouter from "vue-router";

import Default from "@layouts/Default";
import Light from "@layouts/Light";

const Dashboard = () => import(/* webpackChunkName: "main" */ "@pages/Dashboard")
const DashboardOrganization = () => import(/* webpackChunkName: "main" */ "@pages/DashboardOrganization")

const Login = () => import(/* webpackChunkName: "main" */ "@pages/Account/Login.vue")

const ForgotPassword = () => import(/* webpackChunkName: "main" */ "@pages/Account/ForgotPassword")
const ChangePassword = () => import(/* webpackChunkName: "main" */ "@pages/Account/ChangePassword")
const Activation = () => import(/* webpackChunkName: "main" */ "@pages/Account/Activation.vue")

const AccountForm = () => import(/* webpackChunkName: "main" */ "@pages/Account/AccountForm")


const Operators = () => import(/* webpackChunkName: "operators" */ "@pages/Operator/Operators")
const Operator = () => import(/* webpackChunkName: "operators" */ "@pages/Operator/Operator")


const UserSearch = () => import(/* webpackChunkName: "user" */ "@pages/User/UserSearch")
const UserForm = () => import(/* webpackChunkName: "user" */ "@pages/User/UserForm")

const Report = () => import(/* webpackChunkName: "report" */ "@pages/Report/Report")
const ReportUsages = () => import(/* webpackChunkName: "report" */ "@pages/Report/ReportUsages")
const ReportOffline = () => import(/* webpackChunkName: "report" */ "@pages/Report/ReportOffline")
const ReportEnergyProvider = () => import(/* webpackChunkName: "report" */ "@pages/Report/ReportEnergyProvider.vue")

const Alerts = () => import(/* webpackChunkName: "alert" */ "@pages/Alert/Alerts")
const Issues = () => import(/* webpackChunkName: "issue" */ "@pages/Issue/Issues")
const IssueAdd = () => import(/* webpackChunkName: "issue" */ "@pages/Issue/IssueAdd")
const IssueDetail = () => import(/* webpackChunkName: "issue" */ "@pages/Issue/IssueDetail")

const ManageTariffs = () => import(/* webpackChunkName: "static" */ "@pages/Static/ManageTariffs.vue")
const ElectricityIndexSearch = () => import(/* webpackChunkName: "electricity-index" */ "@pages/ElectricityIndex/ElectricityIndexSearch.vue")
const ElectricityIndexForm = () => import(/* webpackChunkName: "electricity-index" */ "@pages/ElectricityIndex/ElectricityIndexForm.vue")
const ElectricityTariffSearch = () => import(/* webpackChunkName: "electricity-tariff" */ "@pages/ElectricityTariff/ElectricityTariffSearch.vue")
const ElectricityTariffForm = () => import(/* webpackChunkName: "electricity-tariff" */ "@pages/ElectricityTariff/ElectricityTariffForm.vue")
const PriceIndexSearch = () => import(/* webpackChunkName: "price-index" */ "@pages/PriceIndex/PriceIndexSearch.vue")
const PriceIndexForm = () => import(/* webpackChunkName: "price-index" */ "@pages/PriceIndex/PriceIndexForm.vue")
const PriceTariffSearch = () => import(/* webpackChunkName: "price-tariff" */ "@pages/PriceTariff/PriceTariffSearch.vue")
const PriceTariffForm = () => import(/* webpackChunkName: "price-tariff" */ "@pages/PriceTariff/PriceTariffForm.vue")


const TokenSearch = () => import(/* webpackChunkName: "subscription" */ "@pages/Token/TokenSearch.vue")

const SubscriptionPlanSearch = () => import(/* webpackChunkName: "subscription" */ "@pages/SubscriptionPlan/SubscriptionPlanSearch.vue")

const MemberRegisterIban = () => import(/* webpackChunkName: "main" */ "@pages/Iban/RegisterIban.vue")

const MemberInvitation = () => import(/* webpackChunkName: "main" */ "@pages/Member/Invitation.vue")
const ImportTariffs = () => import(/* webpackChunkName: "main" */ "@pages/Gireve/ImportTariffs.vue")

const ImportMeterDeviceCdr = () => import(/* webpackChunkName: "main" */ "@pages/MeterDevice/ImportMeterDeviceCdr.vue")
const MeterDeviceSearch = () => import(/* webpackChunkName: "price-index" */ "@pages/MeterDevice/MeterDeviceSearch.vue")
const MeterDeviceReadingForm = () => import(/* webpackChunkName: "meter-device-readings" */ "@pages/MeterDevice/MeterDeviceReadingForm.vue")
const MeterDeviceAdd = () => import(/* webpackChunkName: "price-index" */ "@pages/MeterDevice/MeterDeviceAdd.vue")
const MeterDeviceDetail = () => import(/* webpackChunkName: "price-index" */ "@pages/MeterDevice/MeterDeviceDetail.vue")
const MeterDeviceEdit = () => import(/* webpackChunkName: "price-index" */ "@pages/MeterDevice/Detail/MeterDeviceEdit.vue")
const MeterDeviceInformation = () => import(/* webpackChunkName: "price-index" */ "@pages/MeterDevice/Detail/MeterDeviceInformation.vue")
const MeterDeviceUsages = () => import(/* webpackChunkName: "price-index" */ "@pages/MeterDevice/Detail/MeterDeviceUsages.vue")
const MeterDeviceReadings = () => import(/* webpackChunkName: "price-index" */ "@pages/MeterDevice/Detail/MeterDeviceReadings.vue")

const SubscriptionAnomaly = () => import(/* webpackChunkName: "anomaly" */ "@pages/Anomaly/SubscriptionAnomaly.vue")

const VatRateSearch = () => import(/* webpackChunkName: "vat-rate" */ "@pages/VatRate/VatRateSearch.vue")
const VatRateForm = () => import(/* webpackChunkName: "vat-rate" */ "@pages/VatRate/VatRateForm.vue")
const ReportFinancial = () => import(/* webpackChunkName: "financial" */ "@pages/Report/ReportFinancial.vue")
const TokenDetail = () => import(/* webpackChunkName: "price-index" */ "@pages/Token/TokenDetail.vue")
const TokenDetailInformation = () => import(/* webpackChunkName: "price-index" */ "@pages/Token/Detail/TokenInformation.vue")
const TokenDetailEdit = () => import(/* webpackChunkName: "price-index" */ "@pages/Token/Detail/TokenEdit.vue")
const TokenDetailUsages = () => import(/* webpackChunkName: "price-index" */ "@pages/Token/Detail/TokenUsages.vue")
const TokenSessionAuthorizations = () => import(/* webpackChunkName: "price-index" */ "@pages/Token/Detail/TokenSessionAuthorizations.vue")

const Invoices = () => import(/* webpackChunkName: "financial" */ "@pages/Invoice/Invoices.vue")
const ProformaInvoices = () => import(/* webpackChunkName: "financial" */ "@pages/ProformaInvoice/ProformaInvoices.vue")
const ProformaInvoiceEdit = () => import(/* webpackChunkName: "financial" */ "@pages/ProformaInvoice/ProformaInvoiceEdit.vue")
const ProformaInvoicesByRecipient = () => import(/* webpackChunkName: "financial" */ "@pages/ProformaInvoice/ProformaInvoicesByRecipient.vue")
const BillingRequests = () => import(/* webpackChunkName: "financial" */ "@pages/BillingRequest/BillingRequests.vue")
const BillingRequestInvoices = () => import(/* webpackChunkName: "financial" */ "@pages/BillingRequest/BillingRequestInvoices.vue")
const BillingRequestInvoiceShow = () => import(/* webpackChunkName: "financial" */ "@pages/BillingRequest/BillingRequestInvoiceShow.vue")
const BillingRequestInvoiceEdit = () => import(/* webpackChunkName: "financial" */ "@pages/BillingRequest/BillingRequestInvoiceEdit.vue")

const Agreements = () => import(/* webpackChunkName: "financial" */ "@pages/Agreement/Agreements")
const AgreementForm = () => import(/* webpackChunkName: "financial" */ "@pages/Agreement/AgreementForm")
const MarginManager = () => import(/* webpackChunkName: "financial" */ "@pages/MarginManager/MarginManager")
const RegisterExternalInvoice = () => import(/* webpackChunkName: "financial" */ "@pages/MeterDevice/RegisterExternalInvoice.vue")



import chargingPointRoutes from "./chargingPoint"
import customerRoutes from "./customer"
import locationRoutes from "./location"
import organisationRoutes from "./organisation"
import memberRoutes from "./member"
import SubscriptionPlanForm from "@pages/SubscriptionPlan/SubscriptionPlanForm.vue";
import operatorGroups from "@/router/operatorGroups";




Vue.use(VueRouter)


export default new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '',
      component: Default,
      children: [
        {
          path: '',
          redirect: '/dashboard',
          meta: {
            auth: true,
          }
        },
        {
          name: 'dashboard',
          path: 'dashboard',
          component: Dashboard,
          meta: {
            auth: true,
          },
        },
        {
          name: 'dashboard.organization',
          path: 'dashboard2',
          component: DashboardOrganization,
          meta: {
            auth: true,
          },
        },



        {
          name: 'operators',
          path: 'operators',
          component: Operators,
          meta: {
            auth: true,
          },
        },
        {
          name: 'operator',
          path: 'operators/:id',
          component: Operator,
          meta: {
            auth: true,
          },
        },
        {
          name: 'reports',
          path: 'reports',
          component: Report,
          meta: {
            auth: true,
          },
        },

        {
          name: 'reports.usages',
          path: 'reports/usages',
          component: ReportUsages,
          meta: {
            auth: true,
          },
        },

        {
          name: 'reports.offline',
          path: 'reports/offline',
          component: ReportOffline,
          meta: {
            auth: true,
          },
        },



        {
          name: 'reports.financial',
          path: 'reports/financial',
          component: ReportFinancial,
          meta: {
            auth: true,
          },
        },


        {
          name: 'reports.energy-provider',
          path: 'reports/energy-provider',
          component: ReportEnergyProvider,
          meta: {
            auth: true,
          },
        },

        {
          name: 'alerts',
          path: 'alerts',
          component: Alerts,
          meta: {
            auth: true,
          },
        },

        {
          name: 'issues',
          path: 'issues',
          component: Issues,
          meta: {
            auth: true,
          },
        },

        {
          name: 'issues.add',
          path: 'issues/add',
          component: IssueAdd,
          meta: {
            auth: true,
          },
        },

        {
          name: 'issues.detail',
          path: 'issues/:id',
          component: IssueDetail,
          meta: {
            auth: true,
          },
        },

        {
          name: 'subscription-plans',
          path: 'subscription-plans',
          component: SubscriptionPlanSearch,
          meta: {
            auth: true,
          },
        },

        {
          name: 'subscription-plans.add',
          path: 'subscription-plans/add',
          component: SubscriptionPlanForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'subscription-plans.edit',
          path: 'subscription-plans/edit/:id',
          component: SubscriptionPlanForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'token.search',
          path: 'tokens',
          component: TokenSearch,
          meta: {
            auth: true,
          },
        },
        {
          path: 'tokens/:id',
          component: TokenDetail,
          children: [
            {
              name: 'tokens.detail',
              path: '',
              component: TokenDetailInformation,
            },
            {
              name: 'tokens.edit',
              path: 'edit',
              component: TokenDetailEdit,
            },
            {
              name: 'tokens.usages',
              path: 'usages',
              component: TokenDetailUsages,
            },
            {
              name: 'tokens.authorizations',
              path: 'authorizations',
              component: TokenSessionAuthorizations,
            }
          ]
        },
        {
          name: 'electricity-index',
          path: 'electricity-index',
          component: ElectricityIndexSearch,
          meta: {
            auth: true,
          },
        },

        {
          name: 'electricity-index.add',
          path: 'electricity-index/add',
          component: ElectricityIndexForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'electricity-index.edit',
          path: 'electricity-index/edit/:id',
          component: ElectricityIndexForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'margin-manager',
          path: 'margin-manager',
          component: MarginManager,
          meta: {
            auth: true,
          },
        },

        {
          name: 'electricity-tariff',
          path: 'electricity-tariff/:electricityIndexId',
          component: ElectricityTariffSearch,
          meta: {
            auth: true,
          },
        },

        {
          name: 'electricity-tariff.edit',
          path: 'electricity-tariff/:electricityIndexId/edit/:id',
          component: ElectricityTariffForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'electricity-tariff.add',
          path: 'electricity-tariff/:electricityIndexId/add',
          component: ElectricityTariffForm,
          meta: {
            auth: true,
          },
        },
        {
          name: 'meter-device',
          path: 'meter-device',
          component: MeterDeviceSearch,
          meta: {
            auth: true,
          },
        },

        {
          name: 'meter-device.add',
          path: 'meter-device/add',
          component: MeterDeviceAdd,
          meta: {
            auth: true,
          },
        },
        {
          name: 'meter-device.import-cdrs',
          path: 'meter-device/import-cdrs',
          component: ImportMeterDeviceCdr,
          meta: {
            auth: true,
          },
        },
        {
          path: 'meter-device/:id',
          component: MeterDeviceDetail,
          meta: {
            auth: true,
          },
          children: [
            {
              path: '',
              name: 'meter-device.detail',
              component: MeterDeviceInformation,
            },
            {
              path: 'edit',
              name: 'meter-device.edit',
              component: MeterDeviceEdit,
            },
            {
              path: 'usages',
              name: 'meter-device.usages',
              component: MeterDeviceUsages,
            },
            {
              path: 'readings',
              name: 'meter-device.readings',
              component: MeterDeviceReadings,
            },
          ],
        },

        {
          name: 'price-index',
          path: 'price-index',
          component: PriceIndexSearch,
          meta: {
            auth: true,
          },
        },

        {
          name: 'price-index.add',
          path: 'price-index/add',
          component: PriceIndexForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'price-index.edit',
          path: 'price-index/edit/:id',
          component: PriceIndexForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'price-tariff',
          path: 'price-tariff/:priceIndexId',
          component: PriceTariffSearch,
          meta: {
            auth: true,
          },
        },
        {
          name: 'price-tariff.edit',
          path: 'price-tariff/:priceIndexId/edit/:id',
          component: PriceTariffForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'price-tariff.add',
          path: 'price-tariff/:priceIndexId/add',
          component: PriceTariffForm,
          meta: {
            auth: true,
          },
        },
        {
          name: 'user.search',
          path: 'user',
          component: UserSearch,
          meta: {
            auth: true,
          },
        },
        {
          name: 'user.add',
          path: 'user/add',
          component: UserForm,
          meta: {
            auth: true,
          },
        },
        {
          name: 'user.edit',
          path: 'user/:id',
          component: UserForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'my-account',
          path: 'account',
          component: AccountForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'manage-tariffs',
          path: 'manage-tariffs',
          component: ManageTariffs,
          meta: {
            auth: true,
          },
        },

        {
          name: 'gireve.import-tariffs',
          path: 'gireve/import-tariffs',
          component: ImportTariffs,
          meta: {
            auth: true,
          },
        },

        {
          name: 'anomaly.subscription',
          path: 'anomaly/subscription',
          component: SubscriptionAnomaly,
          meta: {
            auth: true,
          },
        },

        {
          name: 'vat-rates',
          path: 'vat-rates',
          component: VatRateSearch,
          meta: {
            auth: true,
          },
        },

        {
          name: 'vat-rates.add',
          path: 'vat-rates/add',
          component: VatRateForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'vat-rates.edit',
          path: 'vat-rates/edit/:id',
          component: VatRateForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'invoices',
          path: 'invoices/:recipientType?',
          component: Invoices,
          meta: {
            auth: true,
          },
        },

        {
          name: 'proforma-invoices',
          path: 'proforma-invoices/:recipientType?',
          component: ProformaInvoices,
          meta: {
            auth: true,
          },
        },

        {
          name: 'proforma-invoices.edit',
          path: 'proforma-invoices/edit/:id',
          component: ProformaInvoiceEdit,
          meta: {
            auth: true,
          },
        },

        {
          name: 'proforma-invoices-by-recipient',
          path: 'proforma-invoices/by-recipient',
          component: ProformaInvoicesByRecipient,
          meta: {
            auth: true,
          },
        },

        {
          name: 'billing-requests',
          path: 'billing-requests',
          component: BillingRequests,
          meta: {
            auth: true,
          },
        },

        {
          name: 'billing-requests.invoices',
          path: 'billing-requests/invoices',
          component: BillingRequestInvoices,
          meta: {
            auth: true,
          },
        },

        {
          name: 'billing-requests.invoices.show',
          path: 'billing-requests/invoices/show/:id',
          component: BillingRequestInvoiceShow,
          meta: {
            auth: true,
          },
        },

        {
          name: 'billing-requests.invoices.edit',
          path: 'billing-requests/invoices/edit/:id',
          component: BillingRequestInvoiceEdit,
          meta: {
            auth: true,
          },
        },

        {
          name: 'agreements',
          path: 'agreements',
          component: Agreements,
          meta: {
            auth: true,
          },
        },

        {
          name: 'agreements.add',
          path: 'agreements/add',
          component: AgreementForm,
          meta: {
            auth: true,
          },
        },

        {
          name: 'agreements.edit',
          path: 'agreements/edit/:id',
          component: AgreementForm,
          meta: {
            auth: true,
          },
        },





        ...organisationRoutes,
        ...memberRoutes,
        ...locationRoutes,
        ...chargingPointRoutes,
        ...customerRoutes,
        ...operatorGroups,

      ]
    },

    {
      path: '',
      component: Light,
      children: [
        {
          name: 'login',
          path: 'login',
          component: Login,
          meta: {
            auth: false,
          },
        },
        {
          name: 'forgot-password',
          path: 'forgot-password',
          component: ForgotPassword,
        },
        {
          name: 'change-password',
          path: 'change-password',
          component: ChangePassword,
        },
        {
          name: 'activation',
          path: 'activation',
          component: Activation,
        },

        {
            name: 'member.register-iban',
            path: 'member/iban/update/:token',
            component: MemberRegisterIban,
        },

        {
          name: 'member.invitation',
          path: 'member/invitation/:token',
          component: MemberInvitation,
        },

        {
          name: 'meter-devices-reading.edit',
          path: 'meter-devices-readings/:token',
          component: MeterDeviceReadingForm,
        },

        {
          name: 'meter-devices.register-external-invoice',
          path: 'register-external-invoice',
          component: RegisterExternalInvoice,
        },
      ]
    },



  ]
})
